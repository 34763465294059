import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Form, Button, InputGroup } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker';
import { connect } from "react-redux";
import { GetCandidateByID, fetchAllEducationDegrees, fetchAllEducationLevels, postUserEducation } from '../../../features/constants/apis';

const GetVspModal = ({ ID, authToken, refetchUserData, show, handleClose, ...props }) => {
    const [eduID, setEduID] = useState(1);
    const [selectedStartYear, setSelectedStartYear] = useState(new Date().getFullYear());
    const [selectedEndYear, setSelectedEndYear] = useState(new Date().getFullYear());
    const [pursuing, setPursuing] = useState(false);

    const [candidateData, setCandidateData] = useState([])

    const [eduLevels, setEduLevels] = useState([])
    const [eduDegrees, setEduDegrees] = useState([])


    // console.log(ID)

    // Education fields
    const [eduInstitution, setEduInstitution] = useState('')
    const [eduLevel, setEduLevel] = useState('')
    const [eduDegree, setEduDegree] = useState('')

    const renderYearContent = (year) => {
        const tooltipText = `Tooltip for year: ${year}`;
        return <span title={tooltipText}>{year}</span>;
    };

    const handleStartYearChange = (date) => {
        setSelectedStartYear(date.getFullYear());
    };
    const handleEndYearChange = (date) => {
        setSelectedEndYear(date.getFullYear());
    };

    useEffect(() => {
        const eduLevels = async () => {
            const data = await fetchAllEducationLevels()
            setEduLevels(data)
        }
        eduLevels()
    }, [])

    useEffect(() => {
        const eduDegree = async () => {
            const data = await fetchAllEducationDegrees();
            setEduDegrees(data)
        }
        eduDegree()
    }, [])

    // submit education form
    const [validated, setValidated] = useState(false);

    const handlePursuingChange = (e) => {
        setPursuing(e.target.checked);
        setSelectedEndYear(e.target.checked ? null : new Date().getFullYear());
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            // console.log('Form is valid. Submitting...');

            const educationData = {
                'eduID': eduID,
                'startYear': selectedStartYear,
                'endYear': pursuing ? '' : selectedEndYear,
                'institution': eduInstitution,
                'qualification': eduLevel,
                'degree': eduDegree,
                'pursuing': pursuing
            }

            const jsonEduData = JSON.stringify(educationData)
            // console.log(jsonEduData)

            const userEducationDetails = async () => {
                const eduData = await postUserEducation(ID, educationData, authToken)
                // console.log(eduData)

                const candidateInfo = await GetCandidateByID(ID, authToken)
                setCandidateData(candidateInfo)
            };
            await userEducationDetails();

            // console.log(candidateData)

            // console.log(educationData)

            refetchUserData()
            props.onHide()

            setValidated(false);
        }

        // // console.log(eduInstitution)
        setEduInstitution("")
    }

    const handleCloseModal = () => {
        props.onHide()
        setEduInstitution("")
        setValidated(false);
    }
    const [ShowGetVspModal, setShowGetVspModal] = useState(false);
 

    return (
        <div className='container'>
        <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Get VSP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h2>Get a verified skill profile (VSP) to get higher chances of success</h2>
      <h3>Verified skill profiles receive 70% more opportunities on Scopehai</h3>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" >
          Get VSP
        </Button>
        <Button variant="primary" >
          Edit Profile
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, ID, authToken } = auth

    return {
        userData,
        role,
        ID,
        authToken
    }
}


export default connect(mapStateToProps)(GetVspModal)