import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Accordion, Badge } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import job5 from '../images/jobs/job6.png';
import { GetAllApplicationsOfJob, GetCandidates, createJobApplication, fetchJobData, getAllJobs } from '../../features/constants/apis';
import { connect } from 'react-redux';
import { setToken } from '../../features/auth/authSlice';
import { postNewJob } from '../../features/constants/apis';
import { setSelectedJob } from "../../features/data/selectedDriveSlice.js";
import ShimmerJobs from './Shimmer/ShimmerJobs.js';
import { Link } from 'react-router-dom';
import { IoFunnel, IoFunnelOutline } from "react-icons/io5";
import { FaArrowLeft } from 'react-icons/fa';
import DataTable, { createTheme } from 'react-data-table-component';
import AdminJobDetailModal from './Modals/AdminJobDetailModal';
import Header from '../layout/Header/Header.js';
import { Modal } from 'react-bootstrap';
import "./searchurl.css"
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';
import { saveAs } from 'file-saver';


function AdminJobs({ authToken, role, ID }) {

    const navigate = useNavigate();

    const [showJobModal, setShowJobModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState([])

    const [jobID, setJobID] = useState(null)

    const [allJobs, setAllJobs] = useState([]);

    const [candidates, setCandidates] = useState([])

    const [applicationsOfUser, setApplicationsOfUser] = useState([])

    const [searchedTerm, setSearchedTerm] = useState('')



    const [showjson, setShowjson] = useState(false)
    const handleClosejson = () => setShowjson(false);
    const handleShowjson = () => setShowjson(true);
    const [Json, setJson] = useState();
    const exampleJson = [
        {
            "index": 1,
            "jobtitle": "Charity Drive",
            "company": "Hamari Pahchan NGO",
            "location": "Work from home",
            "ctc": "20% of 2000 Funds Raised",
            "link": "https://skyq.tech",
            "AboutTheInternship": "Selected intern's day-to-day responsibilities include",
            "Noo": "3000",
            "SkillsRequired": [
                "Content Marketing",
                "MS-Office"
            ],
            "Whocanapply": "Only those candidates can apply who",
            "perks": [
                "Certificate",
                "Letter of recommendation",
                "Flexible work hours",
                "Job offer"
            ]
        },
        {
            "index": 2,
            "jobtitle": "Social Entrepreneurship",
            "company": "Hamari Pahchan NGO",
            "location": "Work from home",
            "ctc": "Unpaid",
            "link": "https://skyq.tech",
            "AboutTheInternship": "Selected intern's day-to-day responsibilities include",
            "Noo": "3000",
            "SkillsRequired": [
                "Content Marketing",
                "MS-Office"
            ],
            "Whocanapply": "Only those candidates can apply who",
            "perks": [
                "Certificate",
                "Letter of recommendation",
                "Flexible work hours",
                "Job offer"
            ]
        }
    ];


    const [links, setLinks] = useState(Array(15).fill(''));
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [jobDetails, setJobDetails] = useState([]);
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState(new Set());
    const [url, setUrl] = useState('');
    const [postResponse, setPostResponse] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [responseArray, setResponseArray] = useState([]);
    const [json, setjson] = useState('');
    const [processed, setProcessed] = useState(new Set());
    // const [selectedWebsite, setSelectedWebsite] = useState('');
    const [selectedDomain, setSelectedDomain] = useState('');


    const fetchData = async () => {
        try {
            // const response = await axios.get('http://192.168.0.169:4000/scrap-data?url=https://internshala.com/internships');
            // const trimmedData = response.data.response.indexedData; // assuming `trimmedData` is the key that holds the actual data
            // setJobDetails(trimmedData); // Set the fetched data to the state
            // console.log("Data fetched successfully", trimmedData);
        }
        catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCheckboxChange = (index) => {
        setSelected(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(index)) {
                newSelected.delete(index);
            } else {
                if (newSelected.size < 1000) {
                    newSelected.add(index);
                } else {
                    alert('You can only select up to 10 items.');
                }
            }
            return newSelected;
        });
    };

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    };


    // slect all checkboxes at once
    const handleSelectAll = () => {
        if (selected.size === jobDetails.length) {
            // If all are selected, clear the selection
            setSelected(new Set());
        } else {
            // Select all
            const allSelected = new Set(jobDetails.map((_, index) => index));
            setSelected(allSelected);
        }
    };



    // const handleSendSelectedUrls = async () => {
    //     try {
    //         const selectedUrls = Array.from(selected).map(index => jobDetails[index].link);
    //         const responses = [];


    //         for (const url of selectedUrls) {
    //             const response = await axios.get(`http://localhost:4000/detaildata?url=${encodeURIComponent(url)}`);
    //             responses.push(response.data.response);
    //         }

    //         const responseMap = responses.reduce((acc, detail) => {
    //             acc[detail.url] = detail;
    //             return acc;
    //         }, {});

    //         const newJobDetails = jobDetails.map(job => ({
    //             ...job,
    //             ...responseMap[job.link]
    //         }));


    //         setJobDetails(newJobDetails);
    //         setResponseArray(responses);

    //         console.log('Responses', url, responses);
    //         console.log('new array', url, newJobDetails);



    //         alert('All selected URLs have been processed!');

    //     } catch (error) {
    //         setError(error);
    //     }
    // };

    const handleSendSelectedUrls = async () => {
        try {
            // Convert the selected set to an array and filter out already processed IDs
            const selectedArray = Array.from(selected).filter(index => !processed.has(index));
            const batchSize = 100;

            // Function to process each batch
            const processBatch = async (batch) => {
                const selectedUrls = batch.map(index => jobDetails[index].link);
                const responses = [];

                for (const url of selectedUrls) {
                    const response = await axios.get(`http://localhost:4000/detaildata?url=${encodeURIComponent(url)}`);
                    responses.push(response.data.response);
                }

                // Update jobDetails with the new data
                const responseMap = responses.reduce((acc, detail) => {
                    acc[detail.url] = detail;
                    return acc;
                }, {});

                const newJobDetails = jobDetails.map(job => ({
                    ...job,
                    ...responseMap[job.link]
                }));

                setJobDetails(newJobDetails);
                setResponseArray(responses);

                console.log('Batch responses:', responses);
            };

            // Process each batch sequentially
            for (let i = 0; i < selectedArray.length; i += batchSize) {
                const batch = selectedArray.slice(i, i + batchSize);
                await processBatch(batch);

                // Update processed state with the current batch
                setProcessed(prevProcessed => new Set([...prevProcessed, ...batch]));

                // Unselect the processed items
                setSelected(prevSelected => {
                    const newSelected = new Set(prevSelected);
                    batch.forEach(index => newSelected.delete(index));
                    return newSelected;
                });

                // alert(`Batch ${Math.floor(i / batchSize) + 1} processed successfully!`);
            }

            alert('All selected URLs have been processed!');
        } catch (error) {
            setError(error);
            alert('An error occurred while processing the selected URLs.');
        }
    };

    // const handleWebsiteChange = (e) => {
    //     setSelectedWebsite(e.target.value);
    // };

    // const handleDomainChange = (e) => {
    //     setSelectedDomain(e.target.value);
    // };


    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            // Function to extract the domain from the URL
            const extractDomain = (url) => {
                const domain = new URL(url).hostname;
                return domain.startsWith('www.') ? domain.slice(4) : domain;
            };
                const selectedWebsite = extractDomain(url)

                const apiUrl = `http://localhost:4000/scrap-data?url=${encodeURIComponent(url)}&website=${encodeURIComponent(selectedWebsite)}`;

                const response = await axios.get(apiUrl);

                const { indexedData, jobData } = response.data.response;

                if (indexedData.length > 0) {
                    setJobDetails(indexedData);
                } else {
                    setJobDetails(jobData);
                }
                setSelected(new Set());
                setProcessed(new Set());
                alert('URL submitted successfully!');
            } catch (error) {
                setError(error);
                alert('Failed to submit URL.');
            }
        };

        const handleMultipleLinksSubmit = async () => {
            try {
                // Initialize an empty array to hold all the indexed data
                let allJobDetails = [];

                for (const link of links) {
                    if (link) {
                        const apiUrl = `http://localhost:4000/scrap-data?url=${encodeURIComponent(link)}`;

                        const response = await axios.get(apiUrl);
                        console.log('all links', response);

                        // Append the new data to the allJobDetails array
                        allJobDetails = [...allJobDetails, ...response.data.response.indexedData];
                    }
                }

                // Set the collected data to the jobDetails state
                setJobDetails(allJobDetails);
                console.log('all links', allJobDetails);
                setSelected(new Set());
                setProcessed(new Set());
                alert('All URLs submitted successfully!');
            } catch (error) {
                setError(error);
                alert('Failed to submit one or more URLs.');
            }
            handleClose();
        };

        const handleChange = (index, event) => {
            const newLinks = [...links];
            newLinks[index] = event.target.value;
            setLinks(newLinks);
        };



        const handleSaveData = async () => {
            try {
                const response = await fetch('/api/save-jobs', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(jobDetails),
                });

                if (response.ok) {
                    alert('Data saved successfully!');
                } else {
                    alert('Failed to save data.');
                }
            } catch (error) {
                console.error('Error saving data:', error);
                alert('An error occurred while saving data.');
            }
        };


        const jsoneditor = () => {
            console.log("jsoneditor true")
            navigate("/jsoneditor")
        }


        const handleUpdate = async () => {
            try {
                const response = await fetch('http://localhost:4000/api/example', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(json),

                });
                console.log("json format", json)

                if (!response.ok) {
                    throw new Error('Failed to save JSON data');
                }

                const result = await response.json();
                console.log(result.message);

                // onUpdate(json);
            } catch (error) {
                console.error('Error saving JSON data:', error);
            }
        };

        const sendjsondata = () => {
            // Make the POST request to your Express.js backend
            fetch('http://localhost:4000/api/example', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Json),
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(data => {
                    console.log('Success:', data);
                    handleClosejson();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        };

        useEffect(() => {
            setJson(exampleJson);
        }, []);


        // Export table data to CSV

        // const CSVexport = () => {
        //     // Define the headers for the CSV file
        //     const headers = [
        //         "Sr.No",
        //         "Title",
        //         "Company Name",
        //         "Location",
        //         "CTC",
        //         "Description",
        //         "Openings",
        //         "Apply Criteria",
        //         "Perks",
        //         "Tags"
        //     ];

        //     // Map the jobDetails data to CSV rows
        //     const rows = jobDetails.map((item, index) => [
        //         index + 1,  // Serial Number
        //         item.jobtitle || "",  // Title
        //         item.company || "",  // Company Name
        //         item.location || "",  // Location
        //         item.ctc || "",  // CTC
        //         item.AboutTheInternship || "",  // Description
        //         item.Noo || "",  // Openings
        //         item.Whocanapply || "",  // Apply Criteria
        //         item.perks ? item.perks.join(", ") : "",  // Perks (Array to String)
        //         item.SkillsRequired ? item.SkillsRequired.join(", ") : ""  // Tags (SkillsRequired, Array to String)
        //     ]);

        //     // Combine headers and rows into a single array
        //     const csvData = [
        //         headers,
        //         ...rows
        //     ];

        //     // Convert the array to CSV format (string)
        //     const csvContent = csvData.map(e => e.join(",")).join("\n");

        //     // Create a Blob from the CSV string
        //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        //     // Create a link element to trigger the download
        //     const link = document.createElement("a");
        //     if (link.download !== undefined) { // Feature detection
        //         const url = URL.createObjectURL(blob);
        //         link.setAttribute("href", url);
        //         link.setAttribute("download", "job_details.csv");
        //         link.style.visibility = 'hidden';
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //     }
        // };

        const CSVexport = () => {
            const headers = [
                "Sr.No", "Title", "Company Name", "Location", "CTC", "numberOfApplicantsOrZero", "experience", "applayBy", "Description",
                "Openings", "Apply Criteria", "Perks", "Tags"
            ];

            const sanitizeData = (str) => {
                if (typeof str === 'string') {
                    return `"${str.replace(/"/g, '""').replace(/\n/g, ' ')}"`;
                }
                return str;
            };

            // Filter jobDetails to include only those rows with disabled checkboxes
            const filteredJobDetails = jobDetails.filter((_, index) => processed.has(index));

            const rows = filteredJobDetails.map((item, index) => [
                index + 1,
                sanitizeData(item.jobtitle),
                sanitizeData(item.company),
                sanitizeData(item.location),
                sanitizeData(item.ctc),
                sanitizeData(item.numberOfApplicantsOrZero),
                sanitizeData(item.experience),
                sanitizeData(item.applayBy),
                sanitizeData(item.AboutTheInternship),
                sanitizeData(item.Noo),
                sanitizeData(item.Whocanapply),
                sanitizeData(item.perks ? item.perks.join(", ") : ""),
                sanitizeData(item.SkillsRequired ? item.SkillsRequired.join(", ") : "")
            ]);

            const csvData = [headers, ...rows];

            const csvContent = csvData.map(e => e.join(",")).join("\n");

            console.log("import data:", csvContent)

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'filtered_job_details.csv');
        };




        const handleImportToBackend = async () => {
            // Filter jobDetails to include only those rows with disabled checkboxes
            const filteredJobDetails = jobDetails.filter((_, index) => processed.has(index));

            // Log the data to verify it's not empty
            console.log('Filtered Job Details:', filteredJobDetails);
            const jobs = filteredJobDetails.map(item => ({
                'Title': item.jobtitle,
                'Description': item.AboutTheInternship,
                'CompanyName': item.company,
                'Openings': item.Noo,
                'Area': item.location,
                'Location': item.location,
                'Locations': item.location,
                'Education': null,
                'Image': item.logourl,
                'CtcFrom': item.ctc,
                'CtcTo': "",
                'Experience': "0",
                'Skills': item.SkillsRequired,
                'Degree': null,
                'WorkModel': null,
                'JobRole': null,
                'CompanyLocation': item.location,
                'Domain': selectedDomain,
                'Perks': item.perks,
                'ApplyBy': item.applayBy,
                'Type': "Internship",
                'NoOfApplicants': item.NoOfApplicants
            }));

            // Wrap the jobs array in an object
            const dataToSend = { "jobs": jobs };
            try {
                // Send data as JSON to the backend
                const response = await axios.post('http://localhost:4000/fakejob', dataToSend, {
                    headers: {
                        'Content-Type': 'application/json'
                    }

                });
                alert('Data successfully sent to the backend!');
            } catch (error) {
                console.error('Error sending data to backend:', error);
                alert('Failed to send data to the backend.');
                console.log("inserting data", dataToSend);
            }
        };

















        useEffect(() => {
            const fetchAllJobs = async () => {
                const data = await getAllJobs(authToken);
                setAllJobs(data.data);
                console.log(data.data)
            }
            fetchAllJobs();
        }, [authToken]);

        const handleAddNewJob = () => {
            // setIsNewJob(true);

            console.log("true")
            navigate(`/new-job-form`)
        }

        const [activeKey, setActiveKey] = useState(null);

        const handleToggleAccordion = () => {
            setActiveKey(activeKey === '0' ? null : '0');
        };

        const showJobModalHandler = (jobID) => {
            console.log(jobID)
            setJobID(jobID)
            setShowJobModal(true)
            const jobs = allJobs.find(job => job.ID === jobID)
            setSelectedJob(jobs)
        }

        useEffect(() => {
            const fetchCandidates = async () => {
                const candidateData = await GetCandidates(authToken);
                setCandidates(candidateData)
                console.log(candidateData)
                console.log(candidateData?.data[8]?.SkillProfile?.GeneralSkills)
            }
            fetchCandidates()
        }, [authToken])

        const filteredCandidates = candidates?.data?.filter(candidate => {

            if (!selectedJob || (!candidate.SkillProfile.GeneralSkills && !candidate.SkillProfile.ProfessionalSkills)) {
                return false;
            }
            const generalSkillsMatch = selectedJob?.Skills?.GeneralSkills.length > 0 &&
                selectedJob?.Skills?.GeneralSkills.every(skill => candidate?.SkillProfile?.GeneralSkills?.includes(skill));

            const professionalSkillsMatch = selectedJob?.Skills?.ProfessionalSkills.length > 0 &&
                selectedJob?.Skills?.ProfessionalSkills.every(skill => candidate?.SkillProfile?.ProfessionalSkills?.includes(skill));


            return generalSkillsMatch && professionalSkillsMatch;
        });

        const filteredCandidateLength = filteredCandidates ? Object.keys(filteredCandidates).length : 0;
        console.log(filteredCandidateLength)


        // useEffect(() => {
        //     const fetchData = async () => {
        //         const sendData = {
        //             employerID: ID,
        //             jobID: jobID
        //         }
        //         const data = await GetAllApplicationsOfJob(sendData, authToken);
        //         setApplicationsOfUser(data)
        //         console.log(data)
        //     }
        //     fetchData()
        // }, [authToken, ID, jobID])



        const desiredJobsColumns = [
            {
                name: 'ID',
                selector: (row) => row.ID,
                width: '100px',
                sortable: true
            },

            {
                name: 'Title',
                selector: (row) => <span style={{ color: "initial", cursor: "pointer" }} onClick={() => showJobModalHandler(row.ID)}>{row.Title}</span>,
                width: '200px',
                wrap: true,
                sortable: true
            },
            {
                name: 'Company Name',
                selector: (row) => row.CompanyName,
                width: '150px',
                wrap: true,
                sortable: true
            },
            {
                name: 'Location',
                selector: (row) => row.Location,
                width: '150px',
                sortable: true
            },
            {
                name: 'CTC',
                selector: (row) => row.Description,
                width: '300px',
                wrap: true
            },
            {
                name: 'Description',
                selector: (row) => row.Description,
                width: '300px',
                wrap: true
            },
            {
                name: 'Openings',
                selector: (row) => row.Type,
                width: '100px',
                wrap: true
            },
            {
                name: 'Apply criteria',
                selector: (row) => row.Ctc,
                width: '150px',
                wrap: true
            },
            {
                name: 'Perks',
                selector: (row) => row.Experience,
                width: '150px',
                wrap: true
            },
            {
                name: 'Tags',
                selector: (row) => [row.Skills.GeneralSkills].join(', '),
                width: '300px',
                wrap: true
            },
            {
                name: 'Required Professional Skills',
                selector: (row) => [row.Skills.ProfessionalSkills].join(', '),
                width: '300px',
                wrap: true
            }
        ];

        const filteredJobs = allJobs.filter(job => job.Title.toLowerCase().includes(searchedTerm.toLocaleLowerCase()));








        return (
            <>
                <Header></Header>
                <div id="appCapsule">
                    <section className="section full pt-4">
                        <div className="container-table" style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
                            <Button variant="primary mb-2">
                                <Link to="/" type="submit" className='my-2 text-white'><FaArrowLeft style={{ width: '15px', height: '15px', marginRight: "7px" }} />Go to Dashboard</Link>
                            </Button>
                            <Row>
                                <Col className='d-flex justify-content-between'>
                                    <div>
                                        <div className="d-flex align-items-center gap-2">
                                            <h1 className="title center text-dark" style={{ margin: "0" }}>Search url</h1>
                                            <div>
                                                {/* <Link to="" onClick={handleToggleAccordion}>
                                            {activeKey === '0' ?
                                                <>
                                                    <Badge style={{ background: "#ababab" }} bg="none"><IoFunnel style={{ height: 'auto', width: '25px' }} /> Filter</Badge>
                                                </>
                                                :
                                                <>
                                                    <Badge style={{ background: "#ababab" }} bg="none"><IoFunnelOutline style={{ height: 'auto', width: '25px' }} /> Filter</Badge>
                                                </>

                                            }
                                        </Link> */}
                                            </div>
                                            <div className="mb-4">
                                            </div>
                                        </div>

                                        {role === "Candidate" ? <div className="section-title ps-0 text-dark">Top jobs matching your profile</div> : <div className="section-title ps-0 text-dark"></div>}
                                        {/* <p className='text-dark'>We have considered your profi  le and the inputs given by you and have pulled some of the jobs from our recruitement partners.</p> */}
                                    </div>
                                    <div>
                                        {(role === 'Employer' || role === 'Admin') &&
                                            <>
                                                <button onClick={handleShow} className="btn btn-warning" style={{ marginBottom: "5px", right: "0px !important" }}>Add Links</button>
                                                <div style={{ display: "flex" }}>
                                                    

                                                    <Form.Select
                                                        style={{ marginBottom: "5px" }}
                                                        aria-label="Default select example"
                                                        onChange={(e) => setSelectedDomain(e.target.value)}
                                                        value={selectedDomain}
                                                    >
                                                        <option disabled value=''>Select Domain</option>
                                                        <option value="Accounts">Accounts</option>
                                                        <option value="HR">HR</option>
                                                        <option value="Sales & Marketing">Sales & Marketing</option>
                                                        <option value="Business Development">Business Development</option>
                                                        <option value="Sales & Marketing">Sales & Marketing</option>
                                                        <option value="	Digital Marketing">	Digital Marketing</option>
                                                        <option value="Data Science">Data Science</option>
                                                        <option value="Marketing ">Marketing </option>
                                                        <option value="MBA">MBA</option>
                                                        <option value="Data Entry">Data Entry</option>
                                                        <option value="Sales">Sales</option>
                                                        <option value="Social Media Marketing">Social Media Marketing</option>
                                                        <option value="Search Engine Optimization">Search Engine Optimization</option>
                                                        <option value="Bank">Bank</option>
                                                    </Form.Select>
                                                </div>

                                                <form onSubmit={handleFormSubmit} className="d-flex">
                                                    <input
                                                        type="text"
                                                        className="form-control me-2"
                                                        placeholder="Enter URL"
                                                        value={url}
                                                        onChange={handleUrlChange}
                                                    />
                                                    <button type="submit" className="btn btn-primary">Search</button>
                                                </form>
                                                {/* <form onSubmit={handleFormSend} className="d-flex mt-2" >
                                                <textarea rows="3" required="" placeholder="Enter Json" value={json}
                                                    onChange={handlepostChange} class="form-control"></textarea>


                                            </form> */}


                                                <Modal show={show} onHide={handleClose}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Add Links</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>
                                                            {links.map((link, index) => (
                                                                <Form.Group key={index} className="mb-3" controlId={`link${index + 1}`}>
                                                                    <Form.Label>{`Link ${index + 1}`}</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={link}
                                                                        onChange={(event) => handleChange(index, event)}
                                                                        placeholder={`Enter Link ${index + 1}`}
                                                                    />
                                                                </Form.Group>
                                                            ))}
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClose}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={handleMultipleLinksSubmit}>
                                                            Save Links
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>

                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section className="container-table" style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
                        <div className=''>
                            <Row>
                                <Col md="12">
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12 pt-4">
                                    <div className='top-sec-heading d-flex pb-4 justify-content-between'>
                                        <h4>Total {jobDetails.length} jobs found</h4>
                                        <Row>
                                            {/* <Col xs="auto">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search"
                                            className=" mr-sm-2"
                                            onChange={(e) => setSearchedTerm(e.target.value)}
                                        />
                                    </Col> */}
                                            <Col xs="auto">
                                                <button className="btn btn-warning" onClick={handleImportToBackend}>Import to Backend</button>
                                            </Col>
                                            <Col xs="auto">
                                                <button className="btn btn-primary" onClick={handleSendSelectedUrls}>
                                                    Send Selected IDs
                                                </button>
                                            </Col>
                                            <Col xs="auto">
                                                <Button variant="btn btn-primary" onClick={handleSaveData}>Save Data</Button>
                                            </Col>
                                            <Col xs="auto">
                                                <Button variant="outline-warning" onClick={CSVexport}>Export to CSV</Button>
                                            </Col>
                                            <Col xs="auto">
                                                <button onClick={handleShowjson} className="btn btn-warning">Json Editor</button>
                                                <Modal size="lg"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered show={showjson} onHide={handleClosejson}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Json editor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>
                                                            <div className="App">
                                                                <Editor
                                                                    value={exampleJson}
                                                                    onChange={setJson}
                                                                    mode="tree"
                                                                    history
                                                                    ace={ace}
                                                                    theme="ace/theme/github"
                                                                />
                                                            </div>
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClosejson}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={sendjsondata}>
                                                            Save
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </Col>


                                        </Row>
                                    </div>
                                    <div className="table-responsive mt-5" >
                                        <table className="table table-striped " style={{ width: "auto" }}>
                                            <thead className='justify-content-between'>
                                                <tr>
                                                    <th style={{ width: "3rem" }}><input
                                                        type="checkbox"
                                                        onChange={handleSelectAll}
                                                        checked={selected.size === jobDetails.length && jobDetails.length > 0}
                                                    /></th>
                                                    <th style={{ width: "3rem" }}>Sr.No</th>
                                                    <th style={{ width: "10rem" }}>Title</th>
                                                    <th style={{ width: "10rem" }}>Company Name</th>
                                                    <th style={{ width: "8rem" }}>Location</th>
                                                    <th style={{ width: "8rem" }}>Experience</th>
                                                    <th style={{ width: "8rem" }}>Apply by</th>
                                                    <th style={{ width: "8rem" }}>CTC</th>
                                                    <th style={{ width: "8rem" }}>Applicants NO</th>
                                                    {/* <th>Link</th> */}
                                                    <th>Description</th>
                                                    <th style={{ width: "5rem" }}>Openings</th>
                                                    <th>Apply criteria</th>
                                                    <th>Perks</th>
                                                    <th>Tags</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {jobDetails.map((item, index) => (
                                                    <tr key={index} className='truncate-text'>
                                                        <td style={{ width: "20px" }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={selected.has(index)}
                                                                onChange={() => handleCheckboxChange(index)}
                                                                disabled={processed.has(index)}
                                                            />
                                                        </td>
                                                        <td>{index + 1}</td>
                                                        <Tooltip title={item.jobtitle} placement="right">
                                                            <td>{item.jobtitle}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.company} placement="right">
                                                            <td>{item.company}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.location} placement="right">
                                                            <td>{item.location}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.ctc} placement="right">
                                                            <td>{item.experience}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.Applyby} placement="right">
                                                            <td>{item.applayBy}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.ctc} placement="right">
                                                            <td>{item.ctc}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.numberOfApplicantsOrZero} placement="right">
                                                            <td>{item.numberOfApplicantsOrZero}</td>
                                                        </Tooltip>
                                                        {/* <td>{item.link}</td> */}
                                                        <Tooltip title={item.AboutTheInternship} placement="right">
                                                            <td className='truncate-text' style={{ overflow: "hidden" }}>{item.AboutTheInternship}</td>
                                                        </Tooltip>
                                                        <td>{item.Noo}</td>
                                                        <Tooltip title={item.Whocanapply} placement="right">
                                                            <td>{item.Whocanapply}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.perks} placement="right">
                                                            <td>{item.perks}</td>
                                                        </Tooltip>
                                                        <Tooltip title={item.SkillsRequired} placement="right">
                                                            <td>{item.SkillsRequired}</td>
                                                        </Tooltip>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </section>
                </div>
            </>
        )
    }
    const mapStateToProps = ({ auth }) => {
        const { userData, role, fullName, ID, authToken } = auth

        return {
            userData,
            role,
            fullName,
            ID,
            authToken
        }
    }

    const mapDispatchToProps = {
        setToken,
        setSelectedJob
    }

    export default connect(mapStateToProps, mapDispatchToProps)(AdminJobs)


    // job details modal
    function jobDetailModal(props) {

    }