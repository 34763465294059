import React, { useEffect, useState, useRef, useMemo } from "react";
import { ToggleButton, ButtonGroup, Form } from 'react-bootstrap';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import config from '../../config';
import { connect } from "react-redux";
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import AppToast from "../Elements/Notification/AppToast";
import { showAppToast, hideAppToast } from '../../features/utilities/appToastSlice';
import Countdown, { zeroPad } from 'react-countdown';
import { requestOtp, validateOtp, backToLogin, registerUser,setCourseID, setXpLevel } from "../../features/auth/authSlice";
import Logo from '../images/ScopeHaiLogo-two-color.png';
import LoginImg from '../images/Login-screen.png';
import { FaUserGraduate } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { getContrastRatio } from "@mui/material";
import CheckStepNumber from "./CheckStepNumber";
import {useNavigate, useSearchParams} from 'react-router-dom';

const Authentication = ({
  showAppToast,
  appToastVisible,
  appToastMessage,
  appToastIcon,
  appToastBackground,
  appToastPosition,
  requestOtp,
  validateOtp,
  registerUser,
  otpRequestSuccess,
  otpRequestError,
  hideSendOtpButton,
  hideSendOtpButton1,
  showVerifyOtpForm,
  showRegistrationForm,
  otpValidateSuccess,
  otpValidateError,
  backToLogin,
  isVisible,
  setCourseID,
  certCourseID,
  setXpLevel
}) => {

  const inputRef = useRef(null);
  const emailInputRef = useRef(null)
  const navigate = useNavigate()

  const [mobileNumber, setMobileNumber] = useState("")
  const [mobileNumberCC, setMobileNumberCC] = useState("+91")
  const [disableVerifyOtpButton, setDisableVerifyOtpButton] = useState(false)
  const [logo, setAppLogo] = useState("")
  const [disableOtp, setDisableOtp] = useState(true)

  useEffect(() => {
    if (otpRequestSuccess) {
      showAppToast({ toastMessage: 'OTP SENT', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
    }
  }, [otpRequestSuccess, showAppToast])

  useEffect(() => {
    if (otpRequestError) {
      showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
    }
  }, [otpRequestError, showAppToast])

  useEffect(() => {
    console.log(otpValidateSuccess)
    if (otpValidateSuccess) {
      showAppToast({ toastMessage: 'OTP Verified', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
      setDisableVerifyOtpButton(true)
      // navigate(`/`);
      console.log('one')
    }
  }, [otpValidateSuccess, showAppToast])

  useEffect(() => {
    if (otpValidateError) {
      showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
      setDisableVerifyOtpButton(false)
    }
  }, [otpValidateError, showAppToast])

  const handleUserNameValidation = (e) => {
    e.preventDefault()
    // setSendOtpButton(true)
    requestOtp({ mobileNumber: mobileNumber.slice(1) })
  }

  const HandleOtpValidation = (e) => {
    e.preventDefault()
    setDisableVerifyOtpButton(true)
    const otp = e.target.otp.value
    validateOtp({ mobileNumber: mobileNumber.slice(1), otp })
  }

  const [timer, setTimer] = useState(Date.now() + 59999)


  const reSendOtp = (e) => {
    e.preventDefault()
    axios.get(config.apiUrl + '/user/login?mobile='+ mobileNumber).then(res=>{

        if (res.data.message === 'Success') {
          // setResMessage(res.data.data)
          setDisableOtp(true)
          showAppToast({ toastMessage:'OTP Sent', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
          setTimer(Date.now() + 59999)
        } else {
          showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
        }
    })
  }

  const handleBackToLogin = () => {
    backToLogin()
  }

  const Renderer = () => {
    setDisableOtp(false)
  }

  const countdownRenderer = ({ minutes, seconds }) => {
    return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
  }

  useEffect(() => {
    setTimer(Date.now() + 59999)
  }, [showVerifyOtpForm])

  const countDownTimer = useMemo(() => {
    return <Countdown
    key={timer}
    date={timer}
    onComplete={Renderer}
    renderer={countdownRenderer}
  />
  }, [timer])

  // axios.get(config.apiUrl + '/Ai/readWhitelabelJSON/its.me.in').then(res=>{
  //     console.log(res)
  //     if (res.data.message === 'Success') {
  //       setAppLogo(res.data.data.logo)
  //       localStorage.setItem('AppLogo', res.data.data.logo);
  //       console.log(logo)
  //     } else {
  //       showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
  //     }
  // })

  const [showEmailInput, setShowEmailInput] = useState(false)
  const [emailInput, setEmailInput] = useState(false)
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false)

  const handleUserNameInput = (event) => {
    const value = event.target.value
    console.log(value)
    if (value) {
      if (isNaN(value)) {
        setShowEmailInput(true)
        setShowPhoneNumberInput(false)
        setEmailInput(value)
      } else {
        setMobileNumber(`${mobileNumberCC}${value}`)
        setShowEmailInput(false)
        setShowPhoneNumberInput(true)
      }
    } else {
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
      setEmailInput('')
      setMobileNumber('')
    }
  }

  useEffect(() => {
    if (setShowPhoneNumberInput && inputRef && mobileNumber.length <= 4) {
      inputRef?.current?.focus();
    }
  }, [setShowPhoneNumberInput, mobileNumber])

  useEffect(() => {
    if (showEmailInput && emailInputRef && emailInput.length <= 2) {
      emailInputRef?.current?.focus();
    }
  }, [emailInput, showEmailInput])

  const handlePhoneNumberInput = (number) => {
    console.log(number, mobileNumber, mobileNumberCC)
    const codeLength = mobileNumberCC.length
    if (number) {
      if (number.slice(0, codeLength) === mobileNumberCC) {
        setMobileNumber(number)
      } else {
        setMobileNumber(mobileNumber.replace(mobileNumberCC, number))
      }
    } else {
      console.log('else', number)
      setMobileNumber('')
      setEmailInput('')
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
    }
  }

  const handleEmailInput = (event) => {
    const value = event.target.value
    if (value) {
      setEmailInput(value)
    } else {
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
      setEmailInput('')
      setMobileNumber('')
    }
  }

  const handleCountryChange = (event) => {
    const countryCode = getCountryCallingCode(event)
    setMobileNumberCC(`+${getCountryCallingCode(event)}`)
    if (countryCode !== '91') {
      setEmailInput('')
      setShowEmailInput(true)
    } else {
      setShowEmailInput(false)
      setEmailInput('')
    }
  }

  const [radioValue, setRadioValue] = useState('Fresher');
  setXpLevel({xpLevel: radioValue});
  const radios = [
    { icon: <FaUserGraduate />, name: ' Fresher', value: 'Fresher' },
    { icon: <FaBriefcase />, name: ' Experienced', value: 'Experienced' },
  ];

  const [GetUserData, setGetUserData] = useState({
    'username': '',
    'mobileNumber': '',
    'role' : 'Fresher'
  })

  const setRegisteredData = () => {
    const newRegisteredData = {
      'username': GetUserData.username,
      'mobileNumber': mobileNumber.slice(1),
      'role': GetUserData.role
    }
    return newRegisteredData
  }

  const updateUserData = (e) => {
    const field = e.target.name;
    setGetUserData(prevState => ({
      ...prevState,
      [field] : e.target.value
    }));
  }

  const handleUserAdd = (e) => {
    e.preventDefault()
    // console.log(setRegisteredData())
    // setSendOtpButton(true)
    registerUser({...setRegisteredData() })
  }
  
  const setRole = (e) => {
    setRadioValue(e.currentTarget.value);
    updateUserData(e);
  }

  const [searchParams] = useSearchParams();
  const isEmbedded = searchParams.get('src') === 'embeded';
  const getCourseID = searchParams.get('id');
  const getJobID = searchParams.get('jobID');
  const getApplicationType = searchParams.get('type')?.toString();
  const getItemUrl = searchParams.get('Url');

  useEffect(() => {
    // Retrieve ID from URL
    const retrievedSrc = isEmbedded;
    const retrievedCourseID = getCourseID;
    const retrievedJobID = getJobID;
    const retrievedType = getApplicationType;
    const retrievedUrl = getItemUrl;
  
    // Store ID in local storage only if it exists
    if(retrievedCourseID) return localStorage.setItem('certCourseID', retrievedCourseID);
    localStorage.setItem('certSRC', retrievedSrc);
    localStorage.setItem('jobId', retrievedJobID);
    localStorage.setItem('applicationType', retrievedType);
    localStorage.setItem('itemUrl', retrievedUrl);
    if (retrievedCourseID||retrievedJobID||retrievedSrc||retrievedType) {
      console.log(`Stored certCourseID in local storage: ${retrievedCourseID}`);
      console.log(`Stored src is: ${retrievedSrc}`);
      console.log(`Stored job ID is: ${retrievedJobID}`);
      console.log(`Stored application type is: ${retrievedType}`);
      console.log(`Stored item URL is: ${retrievedUrl}`);
    } else {
      console.log("No data found in URL");
    }
  }, [getItemUrl, getApplicationType, getCourseID, getJobID, isEmbedded]);

  const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
  const [isTermsChecked, setIsTermsChecked] = useState(true);
  const handleCheckboxChange = (setter) => () => {
    setter((prev) => !prev);
  };
  const isButtonDisabled = !isPrivacyChecked || !isTermsChecked;

  return (
    <div>
      {!showVerifyOtpForm && <>
      {
        showRegistrationForm ?
        <><div className="login-form mt-1">
          {!localStorage.getItem('certCourseID') && (
            <div className="section">
              <img
                src={Logo}
                alt="Brand Logo"
                className="form-image"
              />
            </div>
          )}
          <div className="section mt-1">
            <h1>REGISTRATION</h1>
            {/* <h4>Sign in to manage your websites</h4> */}
          </div>
          <div className="section mt-1 mb-5">
            <Form onSubmit={handleUserAdd}>
                <Form.Group className="form-group boxed" controlId="exampleForm.ControlInput1">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    id="name1"
                    name="username"
                    value={GetUserData?.username}
                    onChange={updateUserData}
                    required />
                </Form.Group>
                <Form.Group className="form-group boxed">
                <div className="input-wrapper">
                {
                    showPhoneNumberInput &&
                    <PhoneInput
                      className="form-control phoneInput mt-1"
                      international
                      defaultCountry="IN"
                      value={`${mobileNumber}`}
                      onChange={handlePhoneNumberInput}
                      onCountryChange={handleCountryChange}
                      ref={inputRef}
                      maxLength={15}
                      countryCallingCodeEditable={false}
                    />
                }
                {
                    !showPhoneNumberInput && !showEmailInput &&
                    <input
                    type="tel"
                    className="form-control mt-1"
                    id="number"
                    placeholder="Enter Your Mobile Number"
                    maxLength={10}
                    required name="mobileNumber"
                    onChange={handleUserNameInput}
                    />
                }
                {
                    showEmailInput && <input
                    className="form-control mt-1"
                    id="email"
                    placeholder="Enter Email"
                    required
                    name="email"
                    value={emailInput}
                    onChange={handleEmailInput}
                    ref={emailInputRef}
                />
                }
                    <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                    </i>
                </div>
                </Form.Group>
              {/* <div className="form-links mt-2 mb-2 d-flex justify-content-center align-items-center">
                <div className="text-info fw-bold">
                  Dont have Website? <a href="#!">Build Instant Website</a>
                </div>
              </div> */}
              <div className="form-group boxed">
                <ButtonGroup>
                    {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                        name="role"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRole(e)}
                        style={{gap: '5px'}}
                    >
                        {radio.icon} {radio.name}
                    </ToggleButton>
                    ))}
                </ButtonGroup>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckPP" checked />
                <label class="form-check-label" for="flexCheckPP">
                  I read & accept Privacy Policy
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckTC" checked />
                <label class="form-check-label" for="flexCheckTC">
                  I read & accept Terms & Conditions
                </label>
              </div>
              <div className="form-group boxed" style={{paddingRight: '16px', paddingLeft: '16px'}}>
                {hideSendOtpButton1
                  ? <button className="btn btn-primary btn-block btn-lg" type="button" disabled>
                      <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                      Authorizing...
                  </button>
                  : <button className="btn btn-primary btn-block btn-lg" type="submit">
                  GET STARTED
                </button>
                }
              </div>
            </Form>
          </div>
        </div></>
        :
        <div className="login-form mt-1">
          <div className="section mt-1">
            <h2 className="fill-primary">LOGIN</h2>
          </div>
          {((!localStorage.getItem('certCourseID')) || (localStorage.getItem('jobId') !== null)) && (
            <div className="section">
              {/* <img
                src={Logo}
                alt="Brand Logo"
                className="form-image"
              /> */}
              <img
                src={LoginImg}
                alt="Login Img"
                className="form-image"
              />
            </div>
          )}
          
          <div className="section mt-1 mb-5">
            <form onSubmit={handleUserNameValidation}>
              <div class="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckPP"
                  checked={isPrivacyChecked}
                  onChange={handleCheckboxChange(setIsPrivacyChecked)}
                />
                <label class="form-check-label" for="flexCheckPP">
                I read & accept <a href="https://scopehai.com/privacy-policy" target="_blank">Privacy Policy</a>
                </label>
              </div>
              <div class="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckTC"
                  checked={isTermsChecked}
                  onChange={handleCheckboxChange(setIsTermsChecked)}
                />
                <label class="form-check-label" for="flexCheckTC">
                  I read & accept <a href="https://scopehai.com/terms-conditions" target="_blank">Terms & Conditions</a>
                </label>
              </div>
              <div className="form-group boxed" style={{paddingRight: '16px', paddingLeft: '16px'}}>
                <div className="input-wrapper">
                {
                  showPhoneNumberInput &&
                  <PhoneInput
                    className="form-control phoneInput mt-1"
                    international
                    defaultCountry="IN"
                    value={`${mobileNumber}`}
                    onChange={handlePhoneNumberInput}
                    onCountryChange={handleCountryChange}
                    ref={inputRef}
                    maxLength={15}
                    countryCallingCodeEditable={false}
                  />
                }
                {
                  !showPhoneNumberInput && !showEmailInput &&
                  <input
                    type="tel"
                    className="form-control mt-1"
                    id="number"
                    placeholder="Enter Mobile Number"
                    maxLength={10}
                    required name="mobileNumber"
                    onChange={handleUserNameInput}
                  />
                }
                {
                  showEmailInput && <input
                  className="form-control mt-1"
                  id="email"
                  placeholder="Enter Email"
                  required
                  name="email"
                  value={emailInput}
                  onChange={handleEmailInput}
                  ref={emailInputRef}
                />
                }
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
              <div className="form-group boxed login-form" style={{paddingRight: '16px', paddingLeft: '16px'}}>
                {hideSendOtpButton
                  ? <button className="btn btn-primary btn-block btn-lg" type="button" disabled>
                      <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                      Authorizing...
                  </button>
                  : <button 
                    className="btn btn-primary btn-block btn-lg"
                    type="submit"
                    disabled={isButtonDisabled}
                    >
                  Send OTP
                </button>
                }
              </div>
            </form>
          </div>
        </div>
      }
      </>
      }
      {showVerifyOtpForm &&
        <div>
          <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <button type="button" className="btn btn-text-secondary btn-sm border-0 headerButton goBack" onClick={handleBackToLogin}><ion-icon name="chevron-back-outline"></ion-icon></button>
            </div>
            <div className="pageTitle"></div>
            <div className="right"></div>
          </div>
          <div className="login-form mt-5">
            <div className="section">
              <h1>Enter OTP</h1>
              <h4>We sent a verification code on your phone</h4>
            </div>
            <div className="section mt-2 mb-5">
              <form onSubmit={HandleOtpValidation}>
                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <input type="text" className="form-control verify-input" id="smscode" placeholder="••••••" maxlength={6} required name="otp"
                    />
                    {countDownTimer}
                  </div>
                </div>
                <div className="form-links mt-2 mb-2 d-flex justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <span className="d-flex align-items-center justify-content-center">
                      <ion-icon name="stopwatch-outline"></ion-icon>&nbsp;
                    </span>
                    <button type="button" className="btn btn-outline-primary btn-sm rounded" onClick={reSendOtp} disabled={disableOtp}> Resend OTP </button>
                  </div>
                </div>
                <div className="form-button-group container login-form" style={{paddingRight: '16px', paddingLeft: '16px'}}>
                  <button className="btn btn-primary btn-block btn-lg" disabled={disableVerifyOtpButton}>
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      {
        appToastVisible &&
        <AppToast
          message={appToastMessage}
          show={true}
          background={appToastBackground}
          icon={appToastIcon}
          position={appToastPosition}
        />
      }
      <CheckStepNumber></CheckStepNumber>
    </div>
  );
}

const mapStateToProps = ({ appToast, auth }) => {
  const {
    isVisible,
    toastMessage,
    background,
    icon,
    position
  } = appToast

  const {
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    hideSendOtpButton1,
    showVerifyOtpForm,
    showRegistrationForm,
    otpValidateSuccess,
    otpValidateError,
    radioValue
  } = auth

  return {
    appToastVisible: isVisible,
    appToastMessage: toastMessage,
    appToastBackground: background,
    appToastIcon: icon,
    appToastPosition: position,
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    hideSendOtpButton1,
    showVerifyOtpForm,
    showRegistrationForm,
    otpValidateSuccess,
    otpValidateError
  }
}

const mapDispatchToProps = {
  showAppToast,
  hideAppToast,
  requestOtp,
  validateOtp,
  registerUser,
  backToLogin,
  setCourseID,
  setXpLevel
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication)